import React from 'react';
import Greeter from '../views/Greeter';
import Diploma from '../views/Diploma';
import { PageLink, TabLink } from '../models';
import { jsOrange, midBlue, green, pink, purple, red, white, orange } from '../theme/colors';

import staticData from './json/static.json';
import contactData from './json/contact.json';
import sweData from './json/swe.json';
import mbaData from './json/btech.json';
import pkg from '../../package.json';

import { calculateDiplomaProgress } from '../utils';

export const pages: PageLink[] = [
  {
    name: 'Home',
    url: '/',
    icon: 'home',
    isInternal: true,
    comp: () => (
      <Greeter
        staticData={staticData}
        contactData={contactData}
        repoUrl={pkg.repository.url}
      />
    ),
  },
  // {
  //   name: 'MBA Training',
  //   url: '/btech',
  //   icon: 'graduation-cap',
  //   isInternal: true,
    
  //   comp: () => <Diploma diplomaData={mbaData} />,
  // },
];

export const tabs: TabLink[] = [
  {
    name: 'experience.json',
    url: '/experience',
    icon: ['fab','docker'],
    color: white,
    mdFileName: 'experience',
  },
  {
    name: ' skills.yml',
    url: '/skills',
    icon: ['fab', 'aws'],
    color: jsOrange,
    mdFileName: 'skills',
  },
  {
    name: 'projects.config',
    url: '/projects',
    icon: ['fab','jira'],
    color: midBlue,
    mdFileName: 'projects'
  },
  {
    name: 'Certifications.sh',
    url: '/certificates',
    icon: ['fab', 'redhat'],
    color: red,
    mdFileName: 'certificates',
  },
  {
    name: 'Education',
    url: '/education',
    icon: 'university',
    color: orange,
    mdFileName: 'education',
  }
];
